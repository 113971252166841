import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import Menu from "../components/menu";
import { navigate } from "gatsby";

const IndexPage = ({ data }) => {
  const bannerRef = React.createRef();
  const [opacityLevel, setOpacity] = useState(100);
  const checkIfGoToIntro = () => {
    //check if cookie is set that tells it has already visited the intro
    if (document !== undefined) {
      if (document.cookie.indexOf("intro_visited") === -1) {
        //set cookie
        document.cookie = "intro_visited=true; path=/";
        //go to intro
        navigate("/intro");
      }
    }
  };

  const fadeoutBanner = () => {
    if (document.cookie.indexOf("banner_shown") === -1) {
      if (bannerRef.current) {
        bannerRef.current.style = `opacity:${opacityLevel / 100};`;
        if (opacityLevel > 0) {
          setTimeout(() => {
            setOpacity(opacityLevel - 1);
            fadeoutBanner();
          }, 5);
        } else {
          //display none
          bannerRef.current.style = `display:none;`;
          //add cookie banner shown
          if (document !== undefined) {
            document.cookie = "banner_shown=true; path=/";
          }
        }
      }
    }else{
      if (bannerRef.current) {
        bannerRef.current.style = `display:none;`;

      }
    }
  };

  useEffect(() => {
    checkIfGoToIntro();
    fadeoutBanner();
  });

  return (
    <div>
      <Helmet>
        <title>0039 STUDIO - Homepage</title>
      </Helmet>
      <div
        class="flex fixed w-full min-h-screen bg-white z-50"
        id="white-banner"
        ref={bannerRef}
      ></div>

      <div
        id="header"
        name="header"
        class="min-h-screen  bg-white-100 flex lg:flex-row flex-col lg:justify-center "
      >
        <div class="lg:min-h-screen lg:w-5/12 lg:min-w-0 min-w-full bg-white 2xl:pl-40 xl:pl-40 lg:pl-20 lg:pt-20 flex flex-col z-10">
          <div class="lg:pl-0 lg:absolute lg:top-20 lg:pt-0 pt-5 pb-5 lg:pb-10 flex justify-center">
            <Link to="/">
              <StaticImage
                src="../images/logo.png"
                height="100"
                placeholder="tracedSVG"
                quality="100"
                alt="head-logo"
              ></StaticImage>
            </Link>
          </div>
          <div class="text-black mt-auto pb-20 lg:block hidden">
            <ul class="uppercase font-bold top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal text-6xl ">
              <Link to="/work">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  WORK
                </li>
              </Link>
              <Link to="/about">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  ABOUT
                </li>
              </Link>
              <Link to="/studio">
                <li class="transition transform hover:translate-x-6 cursor-pointer">
                  STUDIO
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div class="min-h-screen lg:w-7/12 lg:min-w-0 min-w-full lg:bg-black lg:flex text-white 2xl:pl-40 xl:pl-40 lg:pl-20   lg:flex lg:flex-row hidden lg:justify-start bg-index" >
          <div class="mt-auto pb-20 lg:flex hidden">
            <a href="mailto:info@0039studio.com">
              <div class="uppercase font-bold text-8xl t cursor-pointer flex-column">
                <div>Click</div> <div>here</div> <div>to design</div>
                <div>your story</div>
              </div>
            </a>
          </div>
          <div class="top-menu 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:absolute lg:top-40 lg:right-20 lg:text-6xl md:text-5xl text-4xl flex flex-col lg:justify-start justify-center ">
            <div class="transform -rotate-90 lg:block hidden">
              {/* <div>follow us</div> */}
            </div>
          </div>
        </div>
        <div class="lg:hidden min-h-screen flex flex-col bg-black 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:text-6xl md:text-5xl text-4xl ">
          <div
            class="text-black bg-white text-center min-w-full flex flex-col justify-center uppercase"
            style={{ height: "35vh" }}
          >
            <a href="mailto:info@0039studio.com">
              <div>Click</div> <div>here</div> <div>to design</div>
              <div>your story</div>
            </a>
          </div>
          <div
            class="h-1/3 flex text-white text-center bg-black min-w-full flex flex-col justify-center uppercase pt-20"
            style={{ height: "35vh" }}
          >
            <div>Or</div> <div>learn</div> <div>more</div>
            <div>
              <StaticImage
                src="../images/arrow.png"
                height="68"
                placeholder="tracedSVG"
                quality="50"
                alt="arrow"
                class="mt-10"
              ></StaticImage>
            </div>
          </div>
        </div>
        <Menu></Menu>
      </div>
    </div>
  );
};

export default IndexPage;
