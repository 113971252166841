import * as React from "react";
import { Link } from "gatsby";

const Menu = () => {
    return <div name="menu" id="menu" style={{minHeight:"80vh"}} class="lg:hidden bg-black flex flex-col text-white text-center leading-relaxed 2xl:text-title-main xl:text-title-middle lg:text-title-normal font-bold lg:text-6xl md:text-5xl text-4xl ">
        <Link to="/"><div>HOME</div></Link>
        <Link to="/work"><div>WORK</div></Link>
        <Link to="/about"><div>ABOUT</div></Link>
        <Link to="/studio"><div>STUDIO</div></Link>
    </div>
}

export default Menu;